body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

:focus-visible {
  outline: unset;
}

.sliderImage {
  width: 100%;
  height: auto;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: auto;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  font-size: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.slick-dots li button:focus {
  outline: none;
}

.slick-dots li.slick-active button:before {
  content: '';
  background-color: #c31f43;
  width: 20px;
  height: 10px;
  border-radius: 10px;
  margin-top: 5px;
}

.itemListImageDiv {
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.griditemListImageDiv {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.previewItemListImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: none;
  opacity: 1;
  will-change: transform, opacity;
  border-radius: inherit;
  filter: unset;
  transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
}
.previewItemListImageDiv{
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  margin-bottom: 15px;
}
.itemListImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: none;
  opacity: 1;
  will-change: transform, opacity;
  border-radius: inherit;
  filter: unset;
  transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
}

.vegIcon,
.nonVegIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: 7px;
}

.ItemTitle {
  font-size: 18px;
}

.ItemPrice {
  font-size: 15px;
}

.searchIcon {
  padding-left: 10px;
  padding-right: 10px;
}

.menuBox {
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.bottomMenuBox{
  display: flex;
  padding-bottom: 10px;
}
.viewCartBtn{
  width: 100% !important;
  height: 42px;
  border-radius: 4px !important;
}
.cartItemCount{
  width: 50%;
}
.viewCartBtnDiv{
  width: 40%;
}
.removeCartBtnDiv{
  width: 10%;
}

.removeCartBtn {
  width: 30px !important;
  height: 30px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 100% !important;
  min-width: 30px !important;
  margin-left: 6px !important;
}

.loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999999999;
  background: rgb(255 255 255 / 50%);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderOne {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999999999;
  background: rgb(255 255 255);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartOptions{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  border-top: 1px solid #ddd;
}

.newCustomisationBtn{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.menuBoxOne {
  position: absolute;
  display: flex;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
}
.menuHorizontal {
  overflow-x: auto;
  width: 100%;
  display: -webkit-inline-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-direction: row;
  flex-wrap: nowrap;
}

.menuHorizontal button {
  white-space: nowrap;
  display: block;
  margin-right: 10px;
}

.mainBox {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.listAddIcon {
  font-size: 15px !important;
  position: absolute;
  top: 0;
  right: 0;
}
.customisableText {
  color: grey;
  font-size: 12px !important;
  text-align: center;
}
.ItemAddButton{
  width: 100%;
  min-width: 100px !important;
  height: 40px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.gridItemAddButton{
  width: 70%;
}
.mainBox::-webkit-scrollbar,
.menuHorizontal::-webkit-scrollbar,
.menuModalContent::-webkit-scrollbar
.itemModalContent::-webkit-scrollbar,
.cartModalContent::-webkit-scrollbar,
.modalConetentInnerBox::-webkit-scrollbar,
.itemModalBody::-webkit-scrollbar {
  display: none;
}

.searchBox {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.1);
}

.menuButton {
  border-radius: 4px 0 0 4px !important;
  z-index: 2;
}

.menuModalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.cartModalContainer{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.orderData {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.orderDataTotal {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px dashed #ddd;
}
.itemModalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.menuModalContent {
  max-width: 300px;
  max-height: 400px;
  margin: 0 auto;
  position: absolute;
  bottom: 155px;
  right: 0%;
  width: 100%;
  border-radius: 20px;
  transform: translateX(100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  z-index: 2;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

button {
  text-transform: capitalize !important;
}
.cartModalContent{
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cartModalHeader{
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
}

.itemModalContent {
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  right: 0%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  transform: translateY(100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalConetentInnerBox{
  margin: 15px;
  border-radius: 15px;
  padding-bottom: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.menuModalContent.open {
  transform: translateX(-15px);
}

.itemModalContent.open {
  transform: translateY(0);
}

.cartModalContent.open {
  transform: translateY(0);
}

.menuModalContainer.open, .itemModalContainer.open, .cartModalContainer.open {
  opacity: 1;
  visibility: visible;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modalButtonList button {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.modalButtonList {
  display: block;
  text-align: left;
}
.closeBtnDiv {
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  top: -80px;
}
.closeModalBtn {
  width: 60px !important;
  height: 60px !important;
  border-radius: 120px !important;
  min-width: unset !important;
}

.addOnTitle {
  font-size: .9rem !important;
  font-weight: bold !important;
}
.radioLabel .MuiFormControlLabel-label{
  width: 100%;
}
.radioLabel {
  justify-content: space-between;
  margin-left: 0px !important;
  margin-right: 0px !important;
  flex-direction: row-reverse;
}

.itemModalBody {
  overflow-y: auto;
  max-height: 60vh;
  scrollbar-width: none;  
  -ms-overflow-style: none;
  padding-bottom: 70px;
}
.cartModalFooter{
  display: flex;
  align-items: center;
  padding: 10px;
  position: sticky;
  bottom: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.cartModalBody {
  height: calc(100vh - 115px);
  overflow: auto;
}
.promotitle {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  padding: 15px;
}
.promoSubtitle{
  font-size: 0.8rem !important;
  padding-bottom: 15px;
  margin-left: 44px !important;
}
.promoBottomBox {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
}

.paymentModesDiv{
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  border-radius: 10px;  
}

.promoAddButton{
  padding: 2px;
  margin-left: 44px !important;
}

.promodataBox {
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.promoModalBody{
  height: calc(100vh - 67px);
  overflow: auto;
}

.cartBtnDiv {
  display: flex;
  width: 100%;
}
.checkoutBtnDiv{
  display: flex;
  width: 100%;
}
.clearCart{
  width: calc(30% - 7.5px);
  margin-right: 7.5px;
  background-color: #ddd !important;
  color: #000 !important;
}
.holdOrder{
  width: calc(50% - 7.5px);
  margin-right: 7.5px;
}
.placeOrder{
  width: calc(50% - 7.5px);
  margin-left: 7.5px;
}

.promoCodeDiv {
  margin: 15px;
  padding: 15px;
  border-radius: 4px;
}
.checkoutBtn{
  width: 100%;
}
.modalFooter {
  display: flex;
  align-items: center;
  padding: 10px;
  position: sticky;
  bottom: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.directAddRemoveItem{
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100px;
  justify-content: center;
}

.holdDirectAddRemoveItem{
  display: flex;
  align-items: center;
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #5e5e5e;
  border-radius: 4px;
  width: 100px;
  justify-content: center;
}

.directAddRemoveItem .addRemoveBtn, .directAddRemoveItem .addRemoveBtn{
  color: #fff;
}
.addRemoveItem{
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 25%;
  justify-content: center;
}
.addRemoveBtn {
  width: 45%;
}

.addRemoveQty {
  width: 10%;
}

.addItemBtn {
  width: 100%;
  height: 42px;
  border-radius: 4px !important;
}

.addItemBtnDiv {
  width: 75%;
  padding-left: 10px;
}

.boldLabel {
  font-weight: bold
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.active {
  font-weight: bold !important;
  border-radius: 4px !important;
}

.menuHorizontal span {
  display: flex;
}
.menuHorizontal {
  padding-left: 10px !important;
}
@media only screen and (max-width: 600px) {
  .menuBox, .menuBoxOne {
    position: fixed;
    z-index: 3;
  }
}